const Theme = {
  breakpoints: {
    desktop: '1800px',
    laptop: '1500px',
    tabletLan: '1100px',
    tabletPor: '820px',
    mobile: '480px',
  },
  colors: {
    royalBlue: 'rgba(30, 120, 255, 1)',
    navyBlue: '#040D2C',
    electricBlue: '#9DF7FF',
  },
};

export default Theme;
